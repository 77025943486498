import React from 'react'
import Chip from '@mui/material/Chip'
import { IPatientStatus } from './interfaces'

const UserStatus: React.FC<IPatientStatus> = ({ inactive }) => {
    if (inactive === undefined) {
        return null
    }

    return <>{inactive ? <Chip label='Deactivated' color='secondary' /> : <div></div>}</>
}

export default UserStatus
