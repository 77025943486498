import { gql } from '@apollo/client'

export const FETCH_USER_BY_EMAIL = gql`
    query UserByEmail($email: String!) {
        userByEmail(email: $email) {
            id
            avatar
            avatarLink
            patient {
                id
            }
        }
    }
`

export const FETCH_USER_AVATAR_BY_EMAIL = gql`
    query UserByEmail($email: String!) {
        userByEmail(email: $email) {
            id
            avatar
        }
    }
`

export const FETCH_USERS = gql`
    query Users($offset: Int, $limit: Int, $search: String) {
        users(offset: $offset, limit: $limit, search: $search) {
            count
            userList {
                id
                email
                avatar
                avatarLink
                firstName
                lastName
                birthDate
                state
                preferredName
                canvasPatientId
                subscriptionStatus # Deprecated
                activeSubscription {
                    subscriptionType
                    b2bPartner
                    b2bSubPartner
                }
                college {
                    id
                    name
                }
                inactive
            }
        }
    }
`

export const FETCH_USERS_WITH_CHATS = gql`
    query usersWithGroupChannels(
        $offset: Int
        $limit: Int
        $search: String
        $customType: String!
        $filters: ChannelsFiltersInput
        $providerId: String
    ) {
        usersWithGroupChannels(
            offset: $offset
            limit: $limit
            search: $search
            customType: $customType
            filters: $filters
            providerId: $providerId
        ) {
            count
            userList {
                id
                email
                avatar
                avatarLink
                firstName
                lastName
                birthDate
                state
                preferredName
                canvasPatientId
                subscriptionStatus # Deprecated
                activeSubscription {
                    subscriptionType
                    b2bPartner
                    b2bSubPartner
                }
                college {
                    id
                    name
                    address
                    city
                    state
                    zipCode
                    articles
                    isArchived
                    campusCrisisInfo
                    createdDate
                }
                groupChannel {
                    channelUrl
                    zones
                    data
                    unreadMessageCount
                    lastMessage {
                        id
                        type
                        message
                        customType
                        createdAt
                    }
                }
            }
        }
    }
`

export const FETCH_USER_WITH_MEMBERSHIP_INFO = gql`
    query Query($userId: ID!) {
        userById(id: $userId) {
            firstName
            lastName
            birthDate
            state
            avatar
            avatarLink
            subscriptionStatus # Deprecated
            activeSubscription {
                subscriptionType
                startDate
                endDate
                b2bPartner
                b2bSubPartner
            }
            patient {
                id
                email
                phone
                preferredName
                gender
                homeAddress {
                    line
                    city
                    state
                }
                contact {
                    name
                    relationship
                    phone
                }
            }
            college {
                id
                name
                campusCrisisInfo
            }
            preferredGender
            pronouns
            isTrial # Deprecated-ish
            expirationTrialDate # Deprecated
            revCatSubscriptions {
                currentPlan
            }
        }
    }
`

export const FETCH_MEMBERSHIP_BY_USER_ID = gql`
    query Query($userId: ID!) {
        userById(id: $userId) {
            subscriptionStatus # Deprecated
            subscriptionStartDate # Deprecated
            activeSubscription {
                subscriptionType
                startDate
                endDate
                b2bPartner
                b2bSubPartner
            }
            inactive
            isTrial # Deprecated-ish
            expirationTrialDate # Deprecated
            offerCode
            revCatSubscriptions {
                currentPlan
            }
        }
    }
`

export const FETCH_PATIENT_DATA_BY_USER_ID = gql`
    query UserById($userId: ID!) {
        userById(id: $userId) {
            id
            canvasPatientId
            firstName
            lastName
            birthDate
            preferredGender
            state
            avatar
            avatarLink
            isConsentObtained
            dateOfConsentObtained
            pronouns
            inactive
            subscriptionStatus # Deprecated
            activeSubscription {
                subscriptionType
                startDate
                endDate
            }
            college {
                id
                name
                address
                city
                state
            }
            patient {
                email
                phone
                extension {
                    url
                    valueCode
                }
                firstName
                preferredName
                gender
                lastName
                birthDate
                homeAddress {
                    line
                    city
                    state
                    postalCode
                }
                contact {
                    name
                    relationship
                    phone
                }
                images
                id
                resourceType
            }
        }
    }
`

export const FETCH_TODOS_TITLE_BY_USER_ID = gql`
    query Todos($userId: ID!) {
        userById(id: $userId) {
            todos {
                title
                status
            }
        }
    }
`
export const FETCH_SKILLSETS_BY_USER_ID = gql`
    query Query($userId: ID!) {
        myPathSkillsetListByUserId(userId: $userId) {
            id
            skillsetName
            skillsetChassisType
            startIntensity
            endIntensity
            createdDatetime
        }
    }
`
